import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';

import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import DocumentationSection from '../containers/Documentation';
import BlockLeft from '../containers/BlockLeft';
import BlockRight from '../containers/BlockRight';
import SEO from '../components/seo';

import Image01 from '../../src/images/getting-started/1-start-registration.jpg';
import Image02 from '../../src/images/getting-started/2-first-login.jpg';
import Image03 from '../../src/images/getting-started/3-first-password.jpg';
import Image04 from '../../src/images/getting-started/4-import-passwords.jpg';
import Image05 from '../../src/images/getting-started/5-generate-recovery-code.jpg';
import Image06 from '../../src/images/getting-started/6-setup-multifactor-authentication.jpg';
import Image07 from '../../src/images/getting-started/7-download-extension.jpg';
import { useTranslation, Trans } from 'react-i18next';

export default ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t('Getting Started')}
          lang={pageContext.lang}
          description={t(
            'The quickest way to setup the client of the Psono password manager. Available for Windows, Linux and Mac. Start to install and be ready in minutes now!'
          )}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSmall
            titleText={t('Getting Started')}
            descriptionText={t('Client setup and installation')}
          />

          <BlockLeft titleText={t('Start Registration')} image={Image01}>
            <Trans i18nKey="start_registration">
              Quickest way to start is to visit our
              <a href="https://www.psono.pw/register.html">
                Web Client’s Registration Form
              </a>
              . Follow the instructions. At the end, an e-mail is sent to you to
              activate your account. In this step, all the initial secrets are
              generated. Those initial secrets will later encrypt all your data
              before it leaves your browser and is stored on our server. The
              same goes for the initial secrets themselves; the only difference
              is that they are encrypted with your password before they leave
              your browser.
            </Trans>
          </BlockLeft>

          <BlockRight titleText={t('First Login')} image={Image02}>
            <Trans i18nKey="first_login">
              After registration, you can now login to our
              <a target="_blank" rel="noreferrer" href="https://www.psono.pw/">
                Web Client
              </a>
              . The Web Client will download the encrypted secrets in the
              background and decrypt them with your password.
            </Trans>
          </BlockRight>

          <BlockLeft titleText={t('First Password')} image={Image03}>
            <Trans i18nKey="first_password">
              Now it's time to create our first password. Right-click into your
              datastore and select “New Entry”. You can create folders and move
              items around per drag’n drop in the same way. The password
              datastore is the central place where all your notes, passwords,
              and other secrets live and is encrypted with the initial secrets
              we created during registration.
            </Trans>
          </BlockLeft>

          <BlockRight titleText={t('Import Passwords')} image={Image04}>
            <Trans i18nKey="import_passwords">
              Were you already using another password manager? Psono offers a
              migration feature to easily import other password manager’s export
              files. If your password manager is not listed, then feel free to
              open a
              <a
                href="https://gitlab.com/psono/psono-client/issues"
                rel="nofollow"
              >
                Feature Request
              </a>
              with a dummy export, to speed things up.
            </Trans>
          </BlockRight>

          <BlockLeft titleText={t('Generate Recovery Code')} image={Image05}>
            <Trans i18nKey="generate_recovery_code">
              Humans tend to forget passwords. The <b>only</b> option to recover
              an account when you lose your password is with a recovery code. We
              advise everybody to create one and store it in a secure place.
              Every account has a maximum of one recovery code. Creating a new
              one will always overwrite any existing old one.
            </Trans>
          </BlockLeft>

          <BlockRight
            titleText={t('Setup Multifactor Authentication')}
            image={Image06}
          >
            <Trans i18nKey="setup_mfa">
              The best way to protect your account in case of a password breach
              is a second factor. Psono currently supports
              <a
                href="https://support.google.com/accounts/answer/1066447"
                rel="nofollow"
              >
                Google Authenticator
              </a>
              (and all compatible apps e.g.
              <a href="https://quantauth.com/">Quant</a> or Authy ) and
              <a href="https://www.yubico.com/" rel="nofollow">
                YubiKey OTP
              </a>
              which can be used in conjunction for three-factor authentication.
            </Trans>
          </BlockRight>

          <BlockLeft titleText={t('Download Extension')} image={Image07}>
            <Trans i18nKey="download_extension">
              The usual way now to access your passwords is to open
              <a href="https://psono.pw">psono.pw</a> in your browser. For more
              convenient access and more features, download and install one of
              our extensions (available for
              <a
                href="https://chrome.google.com/webstore/detail/psonopw/eljmjmgjkbmpmfljlmklcfineebidmlo"
                rel="nofollow"
              >
                Chrome
              </a>
              and
              <a
                href="https://addons.mozilla.org/en-US/firefox/addon/psono-pw-password-manager/"
                rel="nofollow"
              >
                Firefox
              </a>
              ). The extension also offers some extended functionality like
              auto-fill of password forms, quick search (type “pp” followed by a
              space into your address bar), advanced security, and much more.
            </Trans>
          </BlockLeft>

          <DocumentationSection />
          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
